const ThankYou = () => import ("./pages/ThankYou.vue");
const FeedbackPage = () => import ("./pages/FeedbackPage.vue");
const UserDetailsForm = () => import("./pages/UserDetailsForm.vue");
const TermsPage = () => import("./pages/TermsPage.vue");
const PrivacyPage = () => import("./pages/PolicyPage.vue");
const CokeFrequencySelector = () => import("./pages/CokeFrequencySelector.vue");
const HomePage = () => import("./pages/HomePage.vue");
const RatingPage = () => import("./pages/RatingPage.vue");



export const ThumsupRoutes = [
  {
    path: '/thumsup',
    component: HomePage,
    meta: {
      title: 'Thumsup!'
    },
    children: [
      {
        path: 'user',
        component: UserDetailsForm
      },
      {
        path:'tnc',
        component:TermsPage
      },
      {
        path:'privacy',
        component:PrivacyPage
      },
      {
        path:'coke-usage',
        component:CokeFrequencySelector
      },{
        path:'ratings',
        component: RatingPage
      },
      {
        path:'feedback',
        component:FeedbackPage
      },
      {
        path:'thankyou',
        component:ThankYou
      }
    ],
  }
];
