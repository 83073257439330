const TruecallerHome = () => import("./TruecallerHome.vue");


export const SaffolaRoutes = [
  {
    path: '/saffola',
    component: TruecallerHome,
    meta: {
      title: 'Saffola #StepUp4YourHeart'
    }
  }
];
