<template>
  <div class="container">
    <iframe class="responsive-iframe" :src="getGameUrl()" :allow="iFrameProps.permissions" loading="lazy"></iframe>
  </div>
</template>

<script>
import { defaultMacros } from "../constants";
import * as AT from "@/store/actionTypes";
export default {
  name: "IframeContainer",
  props: {
    iFrameProps: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    console.log(this.iFrameProps.URL);
    const pixels = this.$route.query;
    this.$store.dispatch(AT.SAVE_PIXELS, pixels);
    this.$store
      .dispatch(AT.FIRE_PIXEL, { eventType: "event", event: "microsite-view" })
      .then(() => console.log())
      .catch((error) => console.log(error));
  },
  methods: {
    getGameUrl() {
      let pixels = this.$store.getters.pixel;
      let macros = {
        campaignId: pixels?.campaignId || defaultMacros.campaignId,
        adClickId: pixels?.adClickId || defaultMacros.adClickId,
        gaid: pixels?.gaid || defaultMacros.gaid,
        campaign: pixels?.campaign || defaultMacros.campaign,
        uid: pixels?.uid || defaultMacros.uid,
        lid: pixels?.lid || defaultMacros.lid,
        cb: pixels?.cb || defaultMacros.cb
      };
      let placement = pixels?.placement || null;
      let gameUrl = this.iFrameProps.URL + this.objectToQueryString(macros);
      if (placement) {
        gameUrl = gameUrl + "&src=" + placement;
      }
      return gameUrl;
    },
    objectToQueryString(obj) {
      return Object.keys(obj)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join("&");
    }
  }
};
</script>
<style scoped>
.container {
  overflow: hidden;
  width: 100%;
  background-color: #0087ff;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
body {
  margin: 0 !important;
}
</style>
