import * as AT from "./actionTypes";
import * as MT from "./mutationTypes";
import { FormService } from "../leadclients/services";

export default {
  [AT.SNACKBAR]: ({ commit }, data) => {
    commit(MT.SET_SNACKBAR, data);
  },
  [AT.APPEND_FORM]: ({ commit }, data) => {
    commit(MT.APPEND_TO_FORM, data);
  },
  [AT.RESET_FORM]: ({ commit }) => {
    commit(MT.RESET_FORM);
  },
  [AT.SAVE_PIXELS]: ({ commit }, pixel) => {
    commit(MT.SAVE_PIXELS, pixel);
  },
  [AT.SET_USER_PROFILE]: ({ commit }, userProfile) => {
    commit(MT.SET_USER_PROFILE, userProfile);
  },
  [AT.RESET_USER_PROFILE]: ({ commit }) => {
    commit(MT.RESET_USER_PROFILE);
  },
  [AT.SET_COUPON_DATA]: ({ commit } ) => {
    return FormService.getCoupon().then(res => {
      commit(MT.SET_COUPON_DATA, res);
      return res;
    }).catch(err=> console.log(err));
  },
  [AT.RESET_COUPON_DATA]: ({ commit }) => {
    commit(MT.RESET_COUPON_DATA);
  },
  [AT.FIRE_PIXEL]: ({ state }, {eventType, event}) => {
    return FormService.firePixels(state.tyPixels, eventType, event);
  },
  [AT.SUBMIT_FORM]: ({ state }) => {
    return FormService.postData(state.formData);
  },
  [AT.SEND_OTP]: ({ commit }, payload) => {
    return FormService.sendOtp(payload).then(res => {
      commit(MT.SET_OTP_REQUEST_ID, res.requestId);
      return res;
    }).catch(err=> console.log(err));
  },
  [AT.VERIFY_OTP]: ({ commit }, payload) => {
    return FormService.verifyOtp(payload).then(res => {
      return res;
    }).catch(err => console.log(err))
      .finally(() => { commit(MT.RESET_OTP_REQUEST_ID); });
  },
};
