export const SNACKBAR = "SNACKBAR";
export const SUBMIT_FORM = "leadclients/SUBMIT_FORM";
export const APPEND_FORM = "leadclients/APPEND_FORM";
export const SAVE_PIXELS = "leadclients/SAVEPIXELS";
export const FIRE_PIXEL = "leadclients/FIREPIXEL";
export const RESET_FORM = "leadclients/RESETFORM";
export const SET_USER_PROFILE = "leadclients/SETUSERPROFILE";
export const RESET_USER_PROFILE = "leadclients/RESETUSERPROFILE";
export const SET_COUPON_DATA = "SET_COUPON_DATA";
export const RESET_COUPON_DATA = "RESET_COUPON_DATA";
export const SEND_OTP = "SEND_OTP";
export const VERIFY_OTP = "VERIFY_OTP";