export const defaultMacros = {
    campaignId: "%eaid!",
    adClickId: "%%CACHEBUSTER%%",
    gaid : "%%ADVERTISING_IDENTIFIER_PLAIN%%",
    campaign : "%ebuy!_%eaid!_%ecid!_%epid!_%ecname!",
    uid : "%%CACHEBUSTER%%",
    lid : "%eaid!",
    cb : "%%CACHEBUSTER%%"
}

