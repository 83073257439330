import HTTP from "@/packages/http";

export const FormService = {
  postData(formData) {
    let adId = formData.formId;
    return new Promise((resolve,reject ) => {
      HTTP.post('?action=save-data&formId='+adId, formData )
        .then(res => resolve(res))
        .catch(error => reject(error));

    });
  },
  getCoupon() {
    return HTTP.post('?action=get-coupons').then(result => result.data).catch(error=> error);
  },
  firePixels(pixels, eventType, event) {
    let pixelUrl = process.env.VUE_APP_PIXEL_URL + eventType+"?";
    pixels.event = event;
    return new Promise((resolve,reject ) => {
      HTTP.get(pixelUrl + this.objectToQueryString(pixels),{headers: {"Access-Control-Allow-Origin": "*"}})
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  },
  objectToQueryString(obj) {
    return Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  },
  sendOtp(payload) {
    return HTTP.post('?action=send', payload).then(result => result.data).catch(error => error.response.data );
  },
  verifyOtp(payload) {
    return HTTP.post('?action=verify', payload).then(result => result.data).catch(error => error.response.data);
  }
};