<template>
  <div class="snackbar">
    <v-snackbar v-model="status" :color="getSnackbarData.color" :timeout="getSnackbarData.timeout" top right>
      <span>{{ getSnackbarData.text }}</span>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "SnackBar",
  data() {
    return {
      status: false
    };
  },
  methods: {
    setStatus(statuesValue) {
      this.status = statuesValue;
    }
  },
  computed: {
    snackbar() {
      return this.$store.getters.snackbar;
    },
    getSnackbarData() {
      if (!this.snackbar || !this.snackbar.status) {
        return {};
      }

      this.setStatus(true);

      return {
        timeout: this.snackbar.timeout || 2500,
        color: this.snackbar && this.snackbar.color ? this.snackbar.color : "success",
        icon: this.snackbar.icon || "",
        text: this.snackbar.text || ""
      };
    }
  }
};
</script>
<style scoped>
.snackbar {
  .v-snack.v-snack--active {
    .v-snack__wrapper {
      .v-snack__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: 500;
        .v-icon {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
:deep(.v-snackbar__content) {
  text-align: center !important;
}
</style>
