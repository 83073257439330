<template class="pa-0 ma-0">
  <div>
    <v-card class="quiz-box ma-2" v-if="couponData.length > 0">
      <v-card-item class="text-center pa-0 ma-0">
        <p class="font-weight-bold" style="color: rgb(217, 57, 26)">Thank you for Participating</p>
        <div class="ma-3 text-start">
          <p class="ma-1" style="font-size: 12px; color: #1a2c72; text-align: center">
            You can now redeem the below coupons
          </p>
          <v-row>
            <v-col v-for="(item, index) in couponData" :key="index" cols="4">
              <v-card class="copoun" @click="openOfferDetail(item)">
                <div>
                  <div class="img-wrapper">
                    <v-img class="align-end offer-img" :src="item.display_image_url" cover> </v-img>
                  </div>

                  <div class="pr-1 pl-1 offer-heading">
                    {{ item.title }}
                  </div>
                  <div class="redeem-btn text-center mt-1">
                    <a style="color: #ffffff; text-decoration: none" @click="openOfferDetail(item)">Get Offer</a>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="">
            <v-col cols="12" class="powered-by">
              <!-- <span style="font-size: 10px">Powered By</span> -->
              <span>Powered By<v-img height="18" src="@/assets/grabon.png"></v-img></span>
            </v-col>
          </v-row>
        </div>
      </v-card-item>
    </v-card>

    <div class="bottom-card-wrapper" v-if="showCouponDetails && selectedItem">
      <v-card class="bottom-card" ref="card">
        <v-row class="mt-1">
          <v-col cols="9" class="pa-4 d-flex justify-center align-center" style="height: 75px">
            <v-card style="width: 60%; box-shadow: none">
              <v-img :src="selectedItem.display_image_url" height="50" cover> </v-img>
              <v-card-title class="text-h6"> </v-card-title>
            </v-card>
          </v-col>
          <v-col class="pb-0 pr-4 pb-3 pt-1 d-flex justify-center align-center">
            <div class="text-end d-flex justify-center align-center" @click="handleClose">
              <span class="mdi mdi-close text-h4"></span>
            </div>
          </v-col>
        </v-row>

        <p class="title">{{ selectedItem.title }}</p>
        <p class="text-caption pa-3">
          {{ truncatedText(selectedItem.description) }}
          <a class="text-caption" href="#" @click.prevent="toggleShowMore">
            {{ isExpanded ? "View less" : "Show more" }}
          </a>
        </p>

        <p v-if="selectedItem.offer_code" class="font-weight-bold your-code">Your Coupon code :</p>
        <div v-if="selectedItem.offer_code" class="code-wrapper">
          <span class="code">{{ selectedItem.offer_code }}</span>
          <v-btn class="font-weight-bold" @click="copyText(selectedItem.offer_code)"
            ><v-icon>mdi mdi-content-copy</v-icon>COPY CODE</v-btn
          >
        </div>
        <div class="pl-5 pr-5 pt-1 how-to-wrapper">
          <p>How to Redeem?</p>
          <div v-html="redemptionSteps" class="steps-container"></div>
        </div>
        <div class="redeem-now mb-2">
          <v-btn color="primary" rounded style="font-weight: bold" :href="selectedItem.redemption_url"
            >Redeem Now</v-btn
          >
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import * as AT from "@/store/actionTypes";
export default {
  name: "CouponCard",
  data: () => ({
    couponData: [],
    showCouponDetails: true,
    redemptionSteps: null,
    selectedItem: null,
    isExpanded: false,
    maxLines: 2
  }),
  mounted() {
    this.$store.dispatch(AT.SET_COUPON_DATA).then((couponData) => {
      this.couponData = couponData;
    });
  },
  methods: {
    truncatedText(description) {
      if (!this.isExpanded) {
        const limitedText = description.split(" ").slice(0, 20).join(" ");
        return limitedText + "...";
      }
      return description;
    },
    toggleShowMore() {
      this.isExpanded = !this.isExpanded;
    },
    getPercentage(title) {
      const regex = /\d+%/g;
      const percentages = title.match(regex);
      return percentages;
    },
    handleClose() {
      this.showCouponDetails = false;
      this.isExpanded = false;
    },
    openOfferDetail(selectedItem) {
      this.showCouponDetails = true;
      this.selectedItem = selectedItem;
      this.redemptionSteps = selectedItem.redemption_steps;
    },
    copyText(code) {
      navigator.clipboard.writeText(code);
      this.$store.dispatch(AT.SNACKBAR, {
        color: "success",
        text: "Code Successfully Copied!",
        timeout: 1000
      });
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px;
  padding: 10px 20px;
  font-weight: 700;
  background: #f4f4f4;
}
.title::before {
  content: "";
  position: absolute;
  margin-top: -9px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #f39c12, #e74c3c);
  transform: translateY(-50%);
  z-index: 0;
}

.title::after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  background: #3498db;
  margin-top: 35px;
}

.redeem-now {
  width: 100%;
  text-align: center;
}
.your-code {
  padding: 5px 20px;
  font-size: 15px;
}
.how-to-wrapper {
  max-height: 150px;
  overflow: scroll;
  p {
    font-size: 14px;
    color: rgb(113, 113, 113);
  }
}
.copy-button {
  font-size: 17px;
  padding: 4px;
}

:deep(.steps-container) {
  font-family: Arial, sans-serif;
  border-radius: 8px;
}

:deep(.steps-container b) {
  font-size: 15px;
  text-decoration: underline;
}

:deep(.steps-container ul) {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 500;
  font-family: sans-serif;
}

.code-wrapper {
  justify-content: center;
  display: flex;
  align-items: center;
}
.code {
  border: dotted;
  background: rgb(226 222 222);
  font-family: "Times New Roman", Times, serif;
  padding: 3px 1pc;
  font-size: 17px;
  font-weight: bold;
}
.go-stps {
  font-size: 10px;
}
.bottom-card {
  bottom: 0;
  left: 0;
  text-align: start;
  width: 100%;
  border-radius: 30px 30px 0 0;
  min-height: 400px;
  position: absolute;
  opacity: 1;
  span {
    text-align: end;
  }
}
@keyframes smooth-appear {
  from {
    bottom: -100%; /* Start from off-screen */
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
.bottom-card-wrapper {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: rgba(225, 225, 225, 0.6);
  animation: smooth-appear 0.1s ease forwards;
}
.description {
  font-size: 4px;
}
.offer-imgs {
  -webkit-box-shadow: -1px 5px 15px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 5px 15px -15px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 5px 15px -15px rgba(0, 0, 0, 0.75);
}
.img-wrapper {
  height: 65px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 1px;
}
.offer-tag {
  z-index: 2;
  color: #c43c1e;
  text-align: center;
  height: 25px;
  width: 25px;
  position: absolute;
  background: #e6b002;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 8px;
  flex-wrap: wrap;
  line-height: 0%;
}
.percentage {
  font-size: 10px;
  font-weight: 600;
}
.up-to {
  font-size: 4px;
}
.redeem-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 8px;
  border-radius: 4px;
  height: 14px;
  justify-content: center;
  margin-left: 20%;
  width: 60%;
  font-weight: 700;
  padding: 1px 2px;
  background: #3535f3;
}
.offer-heading {
  font-size: 6px;
  padding: 2px;
  user-select: none;
  text-align: justify;
  color: #393636;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.copoun {
  border-radius: 10px;
  height: 105px;
}

.card-info {
  text-align: center;
  padding-right: 12px;
}
.powered-by {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;

  font-size: 10px;
}
</style>
