import { createApp }  from 'vue';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import "./styles/app.css";
import router from "./routes";
import store from "./store";
import { VueReCaptcha } from 'vue-recaptcha-v3';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import IframeContainer from "./components/IframeContainer.vue";

const app = createApp(App);
app.use(vuetify);
app.use(router);
app.use(store);
app.use(VueReCaptcha,{siteKey:process.env.VUE_APP_SITE_KEY,loaderOptions:{ autoHideBadge:true}});
app.component('DatePicker', DatePicker);
app.component('iframe-container', IframeContainer);
app.mount('#app');

