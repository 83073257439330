import { createRouter, createWebHistory } from 'vue-router';
import HomePage from "../components/HomePage.vue";
import CouponCard from '@/components/coupon/CouponCard.vue';
import { LeadClientRoutes } from "../leadclients/routes";
import PageNotFound from '../components/PageNotFound.vue';


const routes = [
  {
    path: '/',
    component: HomePage,
    name: 'homepage',
     meta: {
      title: 'Microsite'
    }
  },
  {
    path: "/:pathMatch(.*)",
    component: PageNotFound,
    name: '404',
     meta: {
      title: 'Microsite'
    }
  },
  ...LeadClientRoutes,
  {
    path: '/coupon',
    component: CouponCard,
    name: 'homepage',
     meta: {
      title: 'Microsite'
    }
  },
  
];
const router = createRouter({
  history: createWebHistory(),
  routes, 
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title; 
  } else {
    document.title = 'Microsite';
  }
  next();
});

export default router;
