export const SET_SNACKBAR = "SET_SNACKBAR";
export const APPEND_TO_FORM = "leadclients/APPENDTOFORM";
export const SAVE_PIXELS = "leadclients/SAVEPIXELS";
export const RESET_FORM = "leadclients/RESETFORM";
export const SET_USER_PROFILE = "leadclients/SETUSERPROFILE";
export const RESET_USER_PROFILE = "leadclients/RESETUSERPROFILE";
export const SET_COUPON_DATA = "SET_COUPON_DATA";
export const RESET_COUPON_DATA = "RESET_COUPON_DATA";
export const SET_OTP_REQUEST_ID = "SET_OTP_REQUEST_ID";
export const RESET_OTP_REQUEST_ID = "RESET_OTP_REQUEST_ID";




